.center {
    margin-left: auto;
    margin-right: auto;
  }

  .gap-10 { 
    width:100%; 
    height:15px; 
}

.gap-5 { 
  width:100%; 
  height:10px; 
}

#parent {
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}
.child {
  display: inline-block;
  height: 100%;
}