.w-full {
    width: 98%;
  }
  
  .w-\[100px\] {
    width: 100px;
  }

  .overflow-x-scroll {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .whitespace-nowrap {
    white-space: nowrap;
  }
  
  .p-2 {
    padding: 0.5rem;
  }
  
  .italic {
    font-style: italic;
  }
  .inline-block {
    display: inline-block;
  }
  .p-2 {
    padding: 0.5rem;
  }
  .duration-300 {
    transition-duration: 300ms;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }        