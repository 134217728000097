.link-btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font-size: 0.8em;
    padding: 0px;
    outline: none;
}

.link_cursor {
    cursor: pointer;
}

.link-btn {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font-size: 0.8em;
    padding: 0px;
    outline: none;
  }
