table {
  border-spacing: 5px;
  margin-left: 20px;
  width: 100%;
}

td {
  text-align: left;
  width:100%
}


  .gap-10 { 
      width:100%; 
      height:15px; 
  } 

  .gap-20 { 
      width:100%; 
      height:20px; 
  } 

  .smallLeftOffset {
    margin-left: 10px;
  }

h1 {text-align: left;}

.center {
  text-align: center;    
}

.left {
  text-align: left;
}
