td {
    text-align: center;
  }

  p {
    font-size: 0.4em;    
  }

  * {
    margin: 0;
    padding: 0;
}
.imgbox {
    display: grid;
    height: 100%;
}
.center-fit {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}

div.centered 
{
    text-align: center;
}
div.centered table 
{
    margin: 0 auto; 
    text-align: left;
}

.flex-container100{
  margin: auto;
  width: 100%;
  
}

.flex-container{
    margin: auto;
    width: 50%;
    
  }

.flex-container85{
    margin: auto;
    width: 85%;
    
  }

.flex-container98{
    margin: auto;
    width: 98%;
    
  }

  .flex-container35{
    width: 20%;
    margin-left: auto;
    margin-right: auto;
  }

  .centerText {
    text-align: center;
  }
  
  .center {
    margin-left: auto;
    margin-right: auto;
  }

  .centeredHorizontal
  {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  .gap-20 { 
    width:100%; 
    height:20px; 
}

.ten {
  width: 30%;
  background: tomato;
}

.fifty {
  width: 20%;
  background: turquoise;
}

div.cursor
{
  cursor: pointer;
}

.main-modal {
  max-width: 90%;
}