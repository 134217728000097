label {
    margin: 10px;
}

input {
    margin: 10px;
}

h1 {
    margin: 10px;
}