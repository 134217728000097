.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }

  input[type=text], select, textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
    margin-left: 0px;
  }

  input[type=text], input {
    border: 1px solid #ccc; /* Gray border */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
    margin-left: 0px;
  }

  input[type=text], label {
    margin-bottom: 6px; /* Bottom margin */
    resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
    margin-left: 0px;
  }